import React from 'react'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { useParams,Link } from 'react-router-dom'
import RFooter from '../component/RFooter'
import Navbar from '../component/Navbar'
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import share from '../img/share.png'
import './BlogAbout.css'
import bgp from '../img/blogi.jpg'
import u1 from '../img/review/user.png'
import Newsletter from '../component/Newsletter'

import { useContext } from 'react'
import { BCone } from '../BlogContext'
function BlogAbout() {
  
  const {blogs,cblogs}=useContext(BCone);
  let {id}=useParams();
  const [curblog,ccurblog]=useState(null);

  
useEffect(()=>{
  window.scrollTo(0, 0);

  for(let i=0;i<blogs.length;++i){
      
    if(blogs[i]['_id']==id){
      ccurblog(blogs[i]);
      break;
    }
  
}
},[blogs])
   
  

  

  return (
    <div className='blogabout-main'>
        <Navbar main='blogabout-heading'/>
        {curblog && <><div className='blogabout-heading'>
          <img src={curblog.image} alt='blog img'></img>
          <h1>{curblog.title}</h1>
        </div>

        <div className='blog-content-c'>
          <div className='blog-content'>
            <div>
            {curblog && curblog.content.map((x,i)=>{
                return (<div className='blog-text' key={i} id={i==0?'firstc':''}>
                   { x.subheading && <h2>{x.subheading}</h2>}
                  {x.image && <img src={x.image} alt='img'></img>}
              
                <p>{x.subcontent}</p>
                {
                  curblog.content.uls && curblog.content.uls.map((x,j)=>{
                    return <li key={j+10+i}>{x}</li>
                  })
                }
            </div>)
            })}
            </div>
            <div className='blog-wl'>
              <div className='writer'>
                <div>
                <h2>{curblog.author.name}</h2>
                <img src={u1} alt="writer's image"></img>
                </div>
                <p>{curblog.author.about}</p>
              </div>

         

              {blogs.length > 1 && <div className='latest-post'>
                <h2>LATEST POSTS</h2>
                <Link to={`/about-blog/${blogs[0]['_id']}`}>
                <div className='ls-post'>
                  <h2>{blogs[0].title}</h2>
                  <p>{blogs[0].content[0].subcontent.substring(0, 100)} ...</p>
                </div>
                </Link>
                <Link to={{
                pathname: `/about-blog/${blogs[1]['_id']}`,
              }}>
                <div className='ls-post'>
                  <h2>{blogs[1].title}</h2>
                  <p>{blogs[1].content[0].subcontent.substring(0, 100)} ..</p>
                </div>
                </Link>
              </div>}
            </div>
          </div>
        </div>
        </>
        }
        
        <div className='share-to-c'>
          {curblog && <div className='share-to'>
            <img src={share} alt='share'></img>
          <TwitterShareButton
        title={`${curblog.title}\n Recommended🔹`}
        url={`${window.location.href}\n`}
        //  hashtags={[curblog.tags[0].tag,curblog.tags[1].tag]}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <FacebookShareButton
        url={`https://luminous-begonia-cacc3c.netlify.app/about-blog/${id}`}
        quote={curblog.title}
        hashtag={"#hashtag"}
        description={curblog.content[0].subcontent.substring(0,20)}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
          </div>}
        </div>
        
             <Newsletter/>
      <RFooter/>
    </div>
  )
}

export default BlogAbout