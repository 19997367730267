import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import Navbar from "../component/Navbar";
import RFooter from "../component/RFooter";
import rarrow from "../img/r-arrow.png";

import { useContext } from "react";
import { BCone } from "../BlogContext";

function Blog() {
  const { blogs, cblogs } = useContext(BCone);
  const [topb, ctopb] = useState([]);
  const [sliderb, csliderb] = useState([]);
  const [islider, cislider] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    const t = [];

    if (blogs.length >= 4) {
      for (let i = 1; i < 4; ++i) {
        t.push(blogs[i]);
      }
    } else {
      for (let i = 1; i < blogs.length; ++i) {
        t.push(blogs[i]);
      }
    }

    // Blogs on slider section (3 blogs at a time ~4 to 7)
    const sb = [];

    // if blogs length = 7 then 4 on top section and 3 bottom else start from 5th blog i.e 4 in index till blogs length
    if (blogs.length >= 7) {
      for (let i = 4; i < 7; ++i) {
        sb.push(blogs[i]);
      }
    } else {
      for (let i = 4; i < blogs.length; ++i) {
        sb.push(blogs[i]);
      }
    }

    // updating slider blogs
    csliderb(sb);
    // updating main head blogs state
    if (blogs.length >= 7) {
      cislider(7);
    } else {
      cislider(blogs.length);
    }
    // updating main head blogs state
    ctopb(t);
  }, [blogs]);

  function handleSlider() {
    if (blogs.length <= 7) {
      return;
    }
    //implementing slider

    //updating islider(pointer) to prev + 1
    cislider((prev) => (prev + 1) % blogs.length);

    //dummy array which will store all blogs from islider -> islider+3 i.e 3 blogs
    const sb = [];
    let i = islider;

    //3 blogs to be added in sb array
    let count = 3;

    // if i+1 > length , i points to start
    if (blogs.length) {
      while (count) {
        sb.push(blogs[i]);
        i = (i + 1) % blogs.length;
        --count;
      }
    }

    // updating sliderb state which is holding array to be shown in slider
    csliderb(sb);
  }

  return (
    <div className="blog-main">
      <Navbar main="blog-heading" />
      <div className="blog-heading">
        <h1>THE BLOG</h1>
      </div>

      {blogs.length > 0 && (
        <div className="blog-m-c">
          <div className="blog-m">
            <Link
              to={{
                pathname: `/about-blog/${blogs[0]["_id"]}`,
              }}
            >
              <div className="blog-highlight">
                <img src={blogs[0].image} alt="blog"></img>
                <p className="b-date">02/11/2022</p>
                <h2>{blogs[0].title}</h2>
                <p>{blogs[0].content[0].subcontent.substring(0, 80)} ...</p>
              </div>
            </Link>
            <div className="m-blogs" key={12}>
              {topb.length > 0 &&
                topb.map((x, i) => {
                  return (
                    <Link
                      key={i}
                      to={{
                        pathname: `/about-blog/${blogs[i + 1]["_id"]}`,
                      }}
                    >
                      <div className="t-blog">
                        <img src={x.image} alt="blog"></img>
                        <div>
                          <p className="b-date">02/11/2022</p>
                          <h2>{x.title}</h2>
                          <p>
                            {x.content[0].subcontent.substring(0, 100)} . . .
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      <div className="more-b-c">
        <div className="more-b-heading">
          <h2>EXPLORE MORE</h2>
        </div>
        <div className="more-b">
          {sliderb.length > 0 &&
            sliderb.map((x, i) => {
              return (
                <Link
                  key={i + 12}
                  to={{
                    pathname: `/about-blog/${blogs[i + 4]["_id"]}`,
                  }}
                >
                  <div className="more-blog">
                    <img src={x.image} alt="blog"></img>
                    <div>
                      <p className="b-date">02/11/2022</p>
                      <h2>{x.title}</h2>
                      <p>{x.content[0].subcontent.substring(0, 100)} . . .</p>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
        <div className="slider-btn">
          <img src={rarrow} alt="right arrow" onClick={handleSlider}></img>
        </div>
      </div>

      <RFooter />
    </div>
  );
}

export default Blog;
