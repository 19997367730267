import { LazyLoadImage } from "react-lazy-load-image-component";


import twitter from "../img/socials/twitter.png";
import sqtwitter from "../img/socials/sqtwitter.png";
import facebook from "../img/socials/facebook.png";
import sqfacebook from "../img/socials/sqfacebook.png";
import linkedin from "../img/socials/linkedin.png";
import sqlinkedin from "../img/socials/sqlinkedin.png";
import paypal from "../img/pp.png";
import sqpaypal from "../img/sqpp.png";

import { Link } from "react-router-dom";

import './RFooter.css'

function RFooter(){
return <div className="footer-c" >
            <div className="footer-about" data-aos="fade-up">
              <h3>Qodeit</h3>
              <p>
              Qodeit is a young and active company founded in 2021 to make IT services 
accessible to all small and medium-scale enterprises. We are experienced in 
providing quality Software Solutions and services with a wide range of 
technologies all across the globe. We have been providing research 
implementation support, Application development, Web application 
development, web automation, business process automation, Machine 
learning, cloud-based applications, etc.
Our objective is to bridge the gap between the changing needs of modern 
business with our innovative digital solutions. 
 
We believe in providing quality services and maintaining long-term 
relationships.  
              </p>
              <ul className="footer-socials">
                <li>
                  <a href="#">
                  <LazyLoadImage
              alt="twitter"
              effect="blur"
              src={twitter}
              placeholderSrc={sqtwitter}
              width="50px"
              height="50px"
            />
                  </a>
                </li>
                <li>
                  <a href="#">
                  <LazyLoadImage
              alt="facebook"
              effect="blur"
              src={facebook}
              placeholderSrc={sqfacebook}
              width="50px"
              height="50px"
            />
                  </a>
                </li>
                <li>
                  <a href="#">
                  <LazyLoadImage
              alt="linkedin"
              effect="blur"
              src={linkedin}
              placeholderSrc={sqlinkedin}
              width="50px"
              height="50px"
            />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-r" data-aos="fade-up">
              <Link to='/aboutUs'>
              <div>
                <p>About us</p>
              </div>
              </Link>
              <div>
                <p>Terms and Conditions</p>
              </div>

              <div>
                <p>Fair use policy</p>
              </div>
              <Link to='/contact'>
              <div>
                <p>Contact us</p>
              </div>
              </Link>
              <div>
                <p>Earn with us</p>
              </div>

              <Link to='/blog'>
              <div>
                <p>Blog</p>
              </div>
              </Link>
            </div>

            <div className="paypal" data-aos="fade-up">
                <h2>Payment patner</h2>
                <LazyLoadImage
                alt="paypal"
                effect="blur"
                src={paypal}
                placeholderSrc={sqpaypal}
                width="40px"
                height="40px"
              />
              </div>
            <p className="copyright" >© Copyright 2022 Qodeit</p>

          </div>


}

export default RFooter;