import React, { useEffect, useState } from "react";
import "./Homepage.css";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import RFooter from "../component/RFooter";
import Navbar from "../component/Navbar";
import Stats from "../component/Stats";
import v1 from "../vid/v1.mp4";

import s1 from "../img/service/s1.png";
import s2 from "../img/service/s1.png";
import s3 from "../img/service/s1.png";
import s4 from "../img/service/s1.png";
import s5 from "../img/service/s1.png";
import s6 from "../img/service/s1.png";

import ur1 from "../gumroad/review-img/bighead.svg";
import ur2 from "../gumroad/review-img/user2.svg";
import ur3 from "../gumroad/review-img/user3.svg";
import wi1 from "../gumroad/work-img/w1.jpg";
import wi2 from "../gumroad/work-img/w2.jpg";
import wi3 from "../gumroad/work-img/w3.jpg";

import rarrow from "../img/r-arrow.png";
import sqrarrow from "../img/sqr-arrow.png";

import defaultUser from "../img/review/user.png";
import sqdefaultUser from "../img/review/squser.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

import AOS from "aos";
import "aos/dist/aos.css";

function Homepage() {
  const [i, ci] = useState(0);
  const [ir, cir] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    AOS.init({ duration: 1200 });
    const interval = setInterval(() => {
      cir((seconds) => (seconds + 1) % 5);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const reviews = [
    {
      name: "Nikita Wani",
      src: defaultUser,
      pos: "CEO @educlan",
      review:
        "Educlan.in was developed by team qodeit and they did brilliant work and we have very less time as, we were worried that, we will get quality work or not but the final work is perfect and we were very happy with their services.",
    },
    {
      name: "Vinay Mishra",
      src: defaultUser,
      pos: "@Client",
      review:
        "The best place for my IT solution with the best people around , Qodeit got the best team and customer support. Thanks alot!",
    },
    {
      name: "Lavanya Rao",
      src: defaultUser,
      pos: "@Client",
      review: "Best Tech service providers to scale up business 👍",
    },
    {
      name: "Kavita Aherwar",
      src: defaultUser,
      pos: "@Client",
      review: "Was a great experience working with them.",
    },
    {
      name: "Sanjay Singh Bhandari",
      src: defaultUser,
      pos: "@Local Guide",
      review: "Services are really nice",
    },
  ];
  const works = [
    {
      wi: wi1,
      ui: ur1,
    },
    {
      wi: wi2,
      ui: ur2,
    },
    {
      wi: wi3,
      ui: ur3,
    },
  ];

  return (
    <div>
      <div className="head-video-c">
        <div className="bg-vid">
          <video autoPlay muted loop playsInline>
            <source src={v1} type="video/mp4" />
          </video>
        </div>

        <div className="head-c">
          <Navbar main="head-main" />
          <div className="head-main">
            <div className="head-text">
              <h1 data-aos="fade-down">Qodeit</h1>
              <p className="developT" data-aos="fade-up">
                Developing opportunities for your business.
              </p>
              <p data-aos="fade-up">
                We create digital solutions for the modern business, assisting
                you with web development, application development, web
                applications, Customized automation for task automation, web
                automation, and business process automation implementation to
                help you get your business online. Aid companies in identifying
                the talent they need to succeed. Join us in shaping the future.
              </p>
              <button
                className="head-btn-connect"
                data-aos="flip-down"
                data-aos-delay="1000"
              >
                <Link to="/contact">Connect</Link>
              </button>
            </div>
            <div className="h-stats" data-aos="zoom-in">
              <div>
                <h2>
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={100}
                    duration={3}
                    useEasing={true}
                    useGrouping={true}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=" +"
                  />
                </h2>
                <p>Customers</p>
              </div>
              <div>
                <h2>
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={100}
                    duration={3}
                    useEasing={true}
                    useGrouping={true}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=" +"
                  />
                </h2>
                <p>Customers</p>
              </div>
              <div>
                <h2>
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={100}
                    duration={3}
                    useEasing={true}
                    useGrouping={true}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=" +"
                  />
                </h2>
                <p>Customers</p>
              </div>
            </div>

            <div className="h-stats-2" data-aos="fade-in">
              <div>
                <p>
                  To define and connect each aspect that differentiates your
                  brand image, we put our expertise in brand strategy, brand
                  identity, advertising solutions, digital marketing, web
                  solutions, and social media to work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OUR SERVICES */}
      <div className="service-container">
        <div className="service-c-text" data-aos="fade-up">
          <h1>Our Services</h1>
          <p>
            We are software professionals helping our clients implement, design,
            and develop software tools and services. Here are a few of the
            valuable services we offer.
          </p>
        </div>

        <div className="our-services">
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s1} alt="service"></img>
              <h2>Website development</h2>
              <p>
                We have a team of full-stack developers that create professional
                websites that are developed and maintained. Websites are fully
                coded and can be customized as per the client's requirements.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s2} alt="service"></img>

              <h2>Application development</h2>
              <p>
                In accordance with client requests, we create high-quality
                mobile and web applications of all types that have a polished
                appearance and are maintained & routinely updated by the team.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s3} alt="service"></img>

              <h2>Data Analytics</h2>
              <p>
                Our data analytics software enables companies to keep track of
                their efforts and helps in the decision-making process{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s4} alt="service"></img>

              <h2>AI/ML solutions</h2>
              <p>
                We provide Artificial intelligence and machine learning
                solutions to solve business bottlenecks, including chatbots,
                text editors, facial recognition, etc.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s5} alt="service"></img>

              <h2>Digital process automation</h2>
              <p>
                Our digital process automation enables businesses to automate
                repetitive tasks and boost productivity. WhatsApp automation,
                Twitter automation, and data scraping are some examples.{" "}
              </p>
              <p></p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s6} alt="service"></img>

              <h2>Support and maintenance of existing software</h2>
              <p>
                We offer assistance and support for maintaining and resolving
                problems with current business software.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s6} alt="service"></img>

              <h2>Legacy software modernization </h2>
              <p>
                We assist in updating outdated websites to create newer, better
                versions that are more effective in today's world.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s6} alt="service"></img>

              <h2>Software integration and feature integration</h2>
              <p>
                Our integration services assist with adding new and pertinent
                features to existing software to increase its functionality.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s6} alt="service"></img>

              <h2>Support and maintenance of existing software</h2>
              <p>
                We offer assistance and support for maintaining and resolving
                problems with current business software.{" "}
              </p>
            </div>
          </Link>
          <Link to="/service">
            <div className="sm" data-aos="fade-up">
              <img src={s6} alt="service"></img>

              <h2>Prototype</h2>
              <p>
                We turn your software concepts into working prototypes so you
                can see how they will seem when implemented.{" "}
              </p>
            </div>
          </Link>
        </div>
      </div>

      <div className="testimonials" data-aos="fade-down">
        <div className="service-head-text" data-aos="fade-down">
          <p>We have done it</p>
          <h1>Testimonials</h1>
        </div>
        <Stats />
        <div className="reviews" data-aos="fade-up">
          <div
            className="review-slider"
            data-aos="fade-up"
            data-aos-offset="10"
          >
            <div className="review-head-text">
              <p>Our work speaks louder</p>
              <h1>Lovely people say about us</h1>
            </div>
            <div className="review">
              <div className="customer">
                <LazyLoadImage
                  alt="picture"
                  effect="blur"
                  src={defaultUser}
                  placeholderSrc={sqdefaultUser}
                  width="50px"
                  height="50px"
                />
                <div>
                  <h2>{reviews[ir].name}</h2>
                  <p>{reviews[ir].pos}</p>
                </div>
              </div>
              <p>{reviews[ir].review}</p>
            </div>
          </div>

          <div className="form" data-aos="fade-up" data-aos-offset="10">
            <div className="form-c">
              <form name="hform" method="post">
                <input type="hidden" name="form-name" value="hform" />

                <label>
                  Name
                  <input
                    type="text"
                    placeholder="Your name"
                    name="name"
                  ></input>
                </label>
                <label>
                  Email
                  <input
                    type="email"
                    placeholder="Your email address"
                    name="email"
                  ></input>
                </label>
                <label>
                  Mobile
                  <input
                    type="tel"
                    placeholder="Mobile number"
                    name="number"
                  ></input>
                </label>

                <textarea placeholder="Message" name="message"></textarea>
                <button className="form-btn">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-f">
        <RFooter />
      </div>
    </div>
  );
}

export default Homepage;
