import React from "react";
import "./HWW.css";
import Navbar from "../component/Navbar";
import RFooter from "../component/RFooter";
import hwwm from "../img/hww/hwwm.webp";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function HWW() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  window.scrollTo(0, 0);

  return (
    <div>
      <div className="service-m-c">
        <div className="s-bg-img">
          <img src={hwwm}></img>
        </div>
        <Navbar main="service-head" />
        <div className="service-head">
          <h1 data-aos="fade-down">HOW WE WORK</h1>
          <p data-aos="fade-up">
            The process is defined as a sequence of steps. When followed, it
            helps to achieve a goal. We consider it - the art of thinking
            through. To define and connect each aspect that differentiates your
            brand image, we put our expertise in brand strategy, brand identity,
            advertising solutions, digital marketing, web solutions, and social
            media to work.
          </p>
        </div>
      </div>

      <div className="pservices-c">
        <div className="hww-m">
          <div
            className="hwws"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="800"
          >
            <h2>Discovery Workshop</h2>
            <p>
              To define and connect each aspect that differentiates your brand
              image, we put our expertise in brand strategy, brand identity,
              advertising solutions, digital marketing, web solutions, and
              social media to work.To define and connect each aspect that
              differentiates your brand image, we put our expertise in brand
              strategy, brand identity, advertising solutions, digital
              marketing, web solutions, and social media to work.
            </p>
          </div>
          <div className="hwws" data-aos="fade-up" data-aos-offset="80">
            <h2>Planning</h2>
            <p>
              To define and connect each aspect that differentiates your brand
              image, we put our expertise in brand strategy, brand identity,
              advertising solutions, digital marketing, web solutions, and
              social media to work.To define and connect each aspect that
              differentiates your brand image, we put our expertise in brand
              strategy, brand identity, advertising solutions, digital
              marketing, web solutions, and social media to work.
            </p>
          </div>
          <div className="hwws" data-aos="fade-up" data-aos-offset="80">
            <h2>Design</h2>
            <p>
              To define and connect each aspect that differentiates your brand
              image, we put our expertise in brand strategy, brand identity,
              advertising solutions, digital marketing, web solutions, and
              social media to work.To define and connect each aspect that
              differentiates your brand image, we put our expertise in brand
              strategy, brand identity, advertising solutions, digital
              marketing, web solutions, and social media to work.
            </p>
          </div>
          <div className="hwws" data-aos="fade-up" data-aos-offset="80">
            <h2>Development</h2>
            <p>
              To define and connect each aspect that differentiates your brand
              image, we put our expertise in brand strategy, brand identity,
              advertising solutions, digital marketing, web solutions, and
              social media to work.To define and connect each aspect that
              differentiates your brand image, we put our expertise in brand
              strategy, brand identity, advertising solutions, digital
              marketing, web solutions, and social media to work.
            </p>
          </div>
          <div className="hwws" data-aos="fade-up" data-aos-offset="80">
            <h2>Testing & Maintenance</h2>
            <p>
              To define and connect each aspect that differentiates your brand
              image, we put our expertise in brand strategy, brand identity,
              advertising solutions, digital marketing, web solutions, and
              social media to work.To define and connect each aspect that
              differentiates your brand image, we put our expertise in brand
              strategy, brand identity, advertising solutions, digital
              marketing, web solutions, and social media to work.
            </p>
          </div>
        </div>
      </div>

      <RFooter />
    </div>
  );
}

export default HWW;
