import React, { useState } from 'react'
import './Navbar.css'
import ham from '../gumroad/more.png'
import webLogo from '../gumroad/webLogot.png'

import {Link} from 'react-router-dom'
import { useEffect } from 'react'

function RNavbar({main}) {
  function handleResize(){
    const nav=document.querySelector('#main-ul');
    const ham=document.querySelector('.ham-c');
    if(window.innerWidth <= 1000){
      nav.classList.add('hide');
      ham.classList.remove('hide');
    }
    else{
      nav.classList.remove('hide');
      ham.classList.add('hide');
    }
  }
    window.addEventListener('resize', handleResize);

  window.onscroll = function() {myFunction()};


function myFunction() {
  var header = document.querySelector(".navbar");
var content = document.querySelector(`.${main}`);
var sticky = header?header.offsetTop: null;
  if(header && content){

  if(window.innerWidth>860){
    if (window.pageYOffset > sticky) {
      content.classList.add('martop')
      header.classList.add("sticky");
    } else {
      
      header.classList.remove("sticky");
      content.classList.remove('martop');
    }
  }
}
}

  function smoothScroll(e){
    const to=document.querySelector(`.${e}`);
    to.scrollIntoView({behavior: "smooth"});
  }

  /*
  onClick={()=>smoothScroll('work-c')}
*/




function handleHamClick(){
  const ul=document.querySelector('.ham-ul');
  ul.classList.toggle('hide');
}

useEffect(()=>{
  handleResize();

},[])
  return (
    <div>
      <div className='navtop'>
        <div>
          <img src='https://cdn-icons-png.flaticon.com/512/893/893257.png' alt='gmail'></img>
          <p>contact@qodeit.com</p>
        </div>
        
        <div>
          <img src='https://cdn-icons-png.flaticon.com/512/724/724664.png' alt='phone'></img>
        <p>+1-415-543-4534</p>
        </div>
        
      </div>
        <div className='navbar'>
            <div className='logo'>
                <Link to='/'><img src={webLogo} alt="logo" /></Link>
            </div>
            <ul id='main-ul'>
                <li><Link  to='/'>Home</Link></li>
                <li><Link to='/service'>Services</Link></li>
                <li><Link to='/howWeWork'>How we work</Link></li>
                <li><a target='blank' href='https://luminous-begonia-cacc3c.netlify.app/'>Research</a></li>
                <li><Link to='/blog'>Blog</Link></li>

                <li><Link to='/aboutUs'>About us</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
                <button className='contact-nav-btn'><Link to='/contact'>Contact</Link></button>

            </ul>

            <div className='ham-c hide'>
                <div className='ham-img' onClick={handleHamClick}>
                  <img src={ham} alt='menu'></img>
                </div>
                <ul className='ham-ul hide'>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/service'>Services</Link></li>
                <li><Link to='/howWeWork'>How we work</Link></li>
                <li><a target='blank' href='https://luminous-begonia-cacc3c.netlify.app/'>Research</a></li>
                <li><Link to='/blog'>Blog</Link></li>

                <li><Link to='/aboutUs'>About us</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
                <li><Link to='/contact'>Contact</Link></li>

                </ul>
            </div>
      </div>
    </div>

  )
}

export default RNavbar
