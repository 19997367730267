import React from "react";
import "./Contact.css";

import RFooter from "../component/RFooter";
import facebook from "../img/socials/facebook.png";
import twitter from "../img/socials/twitter.png";

import linkedin from "../img/socials/linkedin.png";

import Navbar from "../component/Navbar";
function Contact() {
  window.scrollTo(0, 0);

  return (
    <div className="contact">
      <Navbar />
      <div className="contact-text">
        <h1>GET IN TOUCH</h1>
        <p>
          WHY DON'T WE START YOUR PROJECT IN A PROFESSIONAL MANNER?<br></br>
          Contact us to let us know how we can help.
        </p>
      </div>

      <div className="contact-form-c">
        <div className="contact-form">
          <div className="cform-c">
            <form>
              <label>
                Name:
                <input type="text"></input>
              </label>
              <label>
                Country
                <input type="text"></input>
              </label>
              <label>
                Email ID
                <input type="email"></input>
              </label>
              <label>
                Number
                <input type="number"></input>
              </label>
              <button className="c-btn">Submit</button>
            </form>
          </div>
          <div className="cform-text">
            <h1>Contact Information</h1>
            <ul className="c-about">
              <li>Bengaluru,India</li>
              <li>(+91) 9212921345</li>
              <li>contact@qodeit.com</li>
            </ul>

            <ul className="c-socials">
              <li>
                <img src={facebook} alt="facebook"></img>
              </li>
              <li>
                <img src={linkedin} alt="linkedin"></img>
              </li>
              <li>
                <img src={twitter} alt="twitter"></img>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="c-footer">
        <RFooter />
      </div>
    </div>
  );
}

export default Contact;
