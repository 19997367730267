import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";
import './Newsletter.css'
function Newsletter() {
    const [em,cem]=useState('');

    useEffect(() => {
        AOS.init({ duration: 1200 });

      }, []);

      async function handleSubmitN(e){
        e.preventDefault();
        if(em==''){
          alert('Email field empty!')
      }

      
      let obj={
        "email":em,
        "subscribed":true,
      }

        await fetch("https://api.ipregistry.co/139.5.240.91?key=os4uhjz7r4rp6g5e")
          .then(function (response) {
            return response.json();
          })
          .then(function (payload) {
            console.log(payload);
           obj.Location=payload.location.country.name;
          }).catch((e)=>{console.log(e)});
       
          console.log(obj);
        await axios.post("https://qodeitblogs2.herokuapp.com/add-sub",obj)
         .then(data => {console.log(data)})
        .catch(error => console.log(error));

        
      }

  return (
    <div className='newsletter-m'>
        <h2>Subscribe to newsletter</h2>
        <form name='newsletter' onSubmit={handleSubmitN}>
            <label>
                <input placeholder='Enter your email address' type='email' value={em} onChange={(e)=>{
                    cem(e.target.value);
                }}></input>
            </label>
            <button>Click</button>
        </form>
    </div>
  )
}

export default Newsletter