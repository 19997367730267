import React from "react";
import Navbar from "../component/Navbar";
import RFooter from "../component/RFooter";
import "./Service.css";
import sptop from "../img/service/sptop.webp";
import s1 from "../img/service/s1.png";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);

    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      <div className="service-m-c">
        <div className="s-bg-img">
          <img src={sptop}></img>
        </div>
        <Navbar main="service-head" />
        <div className="service-head">
          <h1 data-aos="fade-down">Services</h1>
          <p data-aos="fade-up">
            Qodeit is a young and active company founded in 2021 to make the
            lives of PHD. Research/ Masters students easier and make Research
            support & guidance more accessible to students all over the world .
            We are experienced in providing quality research paper, research
            implementation support and thesis support specialized in computer
            science and many more.
          </p>
        </div>
      </div>

      <div className="pservices-c">
        <div className="pservices" data-aos="fade-up" data-aos-offset="0">
          <div
            className="pservice"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="1000"
          >
            <img src={s1} alt="service"></img>
            <h2>Data Extraction, Data Scraping & Research Support</h2>
            <p>
              Qodeit is a young and active company founded in 2021 to make the
              lives of PHD. Research/ Masters students easier and make Research
              support & guidance more accessible to students all over the world
              . We are experienced in providing quality research paper, research
              implementation support and thesis support specialized in computer
              science and many more.
            </p>
          </div>
          <div
            className="pservice"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="1000"
          >
            <img src={s1} alt="service"></img>

            <h2>Data Extraction, Data Scraping & Research Support</h2>
            <p>
              Qodeit is a young and active company founded in 2021 to make the
              lives of PHD. Research/ Masters students easier and make Research
              support & guidance more accessible to students all over the world
              . We are experienced in providing quality research paper, research
              implementation support and thesis support specialized in computer
              science and many more.
            </p>
          </div>
          <div
            className="pservice"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="1000"
          >
            <img src={s1} alt="service"></img>

            <h2>Data Extraction, Data Scraping & Research Support</h2>
            <p>
              Qodeit is a young and active company founded in 2021 to make the
              lives of PHD. Research/ Masters students easier and make Research
              support & guidance more accessible to students all over the world
              . We are experienced in providing quality research paper, research
              implementation support and thesis support specialized in computer
              science and many more.
            </p>
          </div>
          <div className="pservice" data-aos="fade-up" data-aos-offset="50">
            <img src={s1} alt="service"></img>

            <h2>Data Extraction, Data Scraping & Research Support</h2>
            <p>
              Qodeit is a young and active company founded in 2021 to make the
              lives of PHD. Research/ Masters students easier and make Research
              support & guidance more accessible to students all over the world
              . We are experienced in providing quality research paper, research
              implementation support and thesis support specialized in computer
              science and many more.
            </p>
          </div>
          <div className="pservice" data-aos="fade-up" data-aos-offset="50">
            <img src={s1} alt="service"></img>

            <h2>Data Extraction, Data Scraping & Research Support</h2>
            <p>
              Qodeit is a young and active company founded in 2021 to make the
              lives of PHD. Research/ Masters students easier and make Research
              support & guidance more accessible to students all over the world
              . We are experienced in providing quality research paper, research
              implementation support and thesis support specialized in computer
              science and many more.
            </p>
          </div>
        </div>
      </div>

      <RFooter />
    </div>
  );
}

export default Service;
