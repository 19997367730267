import React from 'react'
import './Stats.css'

import { useRef } from 'react';
import CountUp, { useCountUp } from "react-countup";


function Stats() {
  return (
    <div className='achivements'>
        <div className='stats'>
        <h1>
        <CountUp
          start={0}
          end={120}
          duration={4}
          separator=" "
          /> + 
        </h1>
         <p>Successful Projects</p>
        </div>
       <div className='stats'>
       <h1>
        <CountUp
          start={0}
          end={120}
          duration={4}
          separator=" "
          /> + 
        </h1>
            <p>Our Satisfied Clients</p>
        </div>
        <div className='stats'>
        <h1>
        <CountUp
          start={0}
          end={120}
          duration={4}
          separator=" "
          /> + 
        </h1>
            <p>Qualified Team Members</p>
        </div>
        <div className='stats'>
        <h1>
        <CountUp 
          start={0}
          end={120}
          duration={4}
          separator=" "
          
          /> + 
        </h1>
            <p>Awards</p>
        </div>
    </div>
  )
}

export default Stats