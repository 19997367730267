import "./App.css";
import Homepage from "./page/Homepage";
import Service from "./page/Service";
import Contact from "./page/Contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HWW from "./page/HWW";
import Aboutus from "./page/Aboutus";
import Blog from "./page/Blog";
import BlogAbout from "./page/BlogAbout";
import FAQ from "./page/FAQ";

import { BlogContext } from "./BlogContext";
import { useEffect } from "react";
function App() {
  window.scrollTo(0, 0);

  return (
    <BrowserRouter>
      <BlogContext>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Homepage />}></Route>
            <Route path="/service" element={<Service />}></Route>
            <Route path="/howWeWork" element={<HWW />}></Route>
            <Route path="/aboutUs" element={<Aboutus />}></Route>
            <Route exact path="/blog" element={<Blog />}></Route>
            <Route path="/about-blog/:id" element={<BlogAbout />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            <Route exact path="/faq" element={<FAQ />}></Route>
          </Routes>
        </div>
      </BlogContext>
    </BrowserRouter>
  );
}

export default App;
