import React from "react";
import "./FAQ.css";
import Navbar from "../component/Navbar";
import RFooter from "../component/RFooter";
import faq from "../img/faq.webp";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function FAQ() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  window.scrollTo(0, 0);
  return (
    <div>
      <div className="service-m-c">
        <div className="s-bg-img">
          <img src={faq}></img>
        </div>
        <Navbar main="service-head" />
        <div className="service-head" data-aos="fade-up">
          <h1>FAQ</h1>
          <p>Have any queries? We got you</p>
        </div>
      </div>

      <div className="pservices-c">
        <div className="faq-c">
          <div className="faq">
            <div data-aos="fade-up" data-aos-offset="10" data-aos-delay="800">
              <h2>• Are you guys still shooting weddings?</h2>
              <p>
                Yes, we are still shooting weddings and are following the
                necessary protocols as per Government guidelines. At the same
                time, we are also practicing social distancing, washing, and
                sanitizing our hands at regular intervals and also wish to be
                part of a smaller crowd for safety reasons.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-offset="80">
              <h2>• Are you guys still shooting weddings?</h2>
              <p>
                Yes, we are still shooting weddings and are following the
                necessary protocols as per Government guidelines. At the same
                time, we are also practicing social distancing, washing, and
                sanitizing our hands at regular intervals and also wish to be
                part of a smaller crowd for safety reasons.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-offset="80">
              <h2>• Are you guys still shooting weddings?</h2>
              <p>
                Yes, we are still shooting weddings and are following the
                necessary protocols as per Government guidelines. At the same
                time, we are also practicing social distancing, washing, and
                sanitizing our hands at regular intervals and also wish to be
                part of a smaller crowd for safety reasons.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-offset="80">
              <h2>• Are you guys still shooting weddings?</h2>
              <p>
                Yes, we are still shooting weddings and are following the
                necessary protocols as per Government guidelines. At the same
                time, we are also practicing social distancing, washing, and
                sanitizing our hands at regular intervals and also wish to be
                part of a smaller crowd for safety reasons.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-offset="80">
              <h2>• Are you guys still shooting weddings?</h2>
              <p>
                Yes, we are still shooting weddings and are following the
                necessary protocols as per Government guidelines. At the same
                time, we are also practicing social distancing, washing, and
                sanitizing our hands at regular intervals and also wish to be
                part of a smaller crowd for safety reasons.
              </p>
            </div>
          </div>
        </div>
      </div>

      <RFooter />
    </div>
  );
}

export default FAQ;
