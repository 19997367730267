import React from "react";
import "./Aboutus.css";
import Navbar from "../component/Navbar";
import RFooter from "../component/RFooter";
import abm from "../img/about/abm.webp";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function Aboutus() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  window.scrollTo(0, 0);

  return (
    <div>
      <Navbar main="au-c" />
      <div className="au-c">
        <div className="au-text">
          <h1 data-aos="fade-down">
            It’s always about{" "}
            <span data-aos="flip-up" data-aos-delay="1000">
              YOU
            </span>{" "}
            at Qodeit!
          </h1>
          <p data-aos="fade-up">
            We create digital solutions for the modern business, assisting you
            with web development, application development, web applications,
            Customized automation for task automation, web automation, and
            business process automation implementation to help you get your
            business online. Aid companies in identifying the talent they need
            to succeed. Join us in shaping the future.{" "}
          </p>
        </div>

        <div className="au-stats-c">
          <div className="au-stats-l">
            <div data-aos="fade-up" data-aos-offset="10" data-aos-delay="1000">
              <h2>10+</h2>
              <p>years in business</p>
            </div>
            <div data-aos="fade-up" data-aos-offset="10" data-aos-delay="1000">
              <h2>10+</h2>
              <p>years in business</p>
            </div>
            <div data-aos="fade-up" data-aos-offset="10" data-aos-delay="1000">
              <h2>10+</h2>
              <p>years in business</p>
            </div>
            <div data-aos="fade-up" data-aos-offset="10" data-aos-delay="1000">
              <h2>10+</h2>
              <p>years in business</p>
            </div>
          </div>
          <img
            src={abm}
            alt="team"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="10"
          ></img>
        </div>
      </div>

      <div className="our-story">
        <h2 data-aos="fade-down" data-aos-offset="90">
          Our Story
        </h2>
        <p data-aos="fade-up" data-aos-offset="90">
          Qodeit is a young and active company founded in 2021 to make IT
          services accessible to all small and medium-scale enterprises. We are
          experienced in providing quality Software Solutions and services with
          a wide range of technologies all across the globe. We have been
          providing research implementation support, Application development,
          Web application development, web automation, business process
          automation, Machine learning, cloud-based applications, etc. Our
          objective is to bridge the gap between the changing needs of modern
          business with our innovative digital solutions. We believe in
          providing quality services and maintaining long-term relationships.
        </p>
      </div>

      <div className="ethos-c">
        <h2 data-aos="fade-down">Our Ethos</h2>
        <div className="ethos">
          <div data-aos="fade-up">
            <p>Clients are friends. Each of their problems are ours.</p>
          </div>
          <div data-aos="fade-down">
            <p>
              We don’t assume. We arrive at solutions through careful
              inferences.
            </p>
          </div>
          <div data-aos="fade-up">
            <p>Ownership and accountability are our everyday practice.</p>
          </div>
          <div data-aos="fade-down">
            <p>100+ digital talents care about your project.</p>
          </div>
          <div data-aos="fade-up">
            <p>Expect design thinking, creativity and problem solving.</p>
          </div>
          <div data-aos="fade-down">
            <p>We know how to play digital for brand value.</p>
          </div>
          <div data-aos="fade-up">
            <p>We're a consumer engagement one-stop shop.</p>
          </div>
          <div data-aos="fade-down">
            <p>We've got an eye for functional design and impactful content.</p>
          </div>
        </div>
      </div>
      <RFooter />
    </div>
  );
}

export default Aboutus;
